import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, catchError, Observable, tap, throwError, timeout} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {UserLoginDto} from './UserLoginDto';
import {UserRegistrationDto} from './UserRegistrationDto';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from './local-storage.service';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public redirectUrl: string | null = null;

  private loggedIn = new BehaviorSubject<boolean>(false);

  private _loggedInEmail = new BehaviorSubject<string>('');

  constructor(private http: HttpClient,
              private storageService: LocalStorageService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object) {
    const token = this.storageService.getItem('token');
    const isLoggedIn = isPlatformBrowser(this.platformId) && !!token;
    this.loggedIn = new BehaviorSubject<boolean>(isLoggedIn);
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
  }

  get loggedInEmail(): BehaviorSubject<string> {
    return this._loggedInEmail;
  }

  setLoggedInEmail(value: string) {
    this._loggedInEmail.next(value);
  }

  register(email: string, username: string, password: string): Observable<HttpResponse<any>> {
    const body = new UserRegistrationDto(email, username, password, Intl.DateTimeFormat().resolvedOptions().timeZone);

    return this.http.post(`${environment.apiURL}/user/register`, body, {observe: 'response', responseType: 'text'}).pipe(
      tap((response: HttpResponse<any>) => {
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  funnel(email: string): Observable<HttpResponse<any>> {
      return this.http.post(`${environment.apiURL}/user/funnel?email=${email}`, {}, {observe: 'response', responseType: 'text'}).pipe(
        tap((response: HttpResponse<any>) => {
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  login(email: string, password: string): Observable<HttpResponse<any>> {
    const body = new UserLoginDto(email, password);

    return this.http.post(`${environment.apiURL}/user/login`, body, {observe: 'response', responseType: 'text'}).pipe(
      tap((res: HttpResponse<any>) => {
        const token = res.headers.get('Authorization');
        this.storageService.setItem('token', token);
        this.storageService.setItem('email', email);
        this.setLoggedIn(true);
        this.setLoggedInEmail(email);
      }),
      catchError((error) => {
        this.setLoggedIn(false);
        this.setLoggedInEmail('');
        return throwError(error);
      })
    );
  }

  logout() {
    const currentPath = this.router.url.split('?')[0].split('#')[0];
    this.storageService.setItem('redirectAfterLogin', currentPath);

    this.storageService.removeItem('token');
    this.storageService.removeItem('email');
    this.storageService.removeItem('notionUserId');
    this.setLoggedIn(false);
    this.setLoggedInEmail('');

    const isOnPrivatePage = this.router.url.startsWith('/competition') || this.router.url.startsWith('/start') || this.router.url.startsWith('/profile');
    if (isOnPrivatePage) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/']);
    }
  }

  delete(email: string, password: string) {
    const body = new UserLoginDto(email, password);

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response' as 'response',
      responseType: 'text' as 'json',
      withCredentials: true,
    };

    return this.http.post(`${environment.apiURL}/user/delete`, body, options).pipe(
      tap(() => {
        this.setLoggedIn(false);
        this.setLoggedInEmail('');
        this.storageService.removeItem('token');
        this.storageService.removeItem('email');
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  resendConfirmation(email: string) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response' as 'response',
      responseType: 'text' as 'text',
    };

    return this.http.post(`${environment.apiURL}/user/resend/confirmation?email=${email}`, {}, options).pipe(
      tap(() => {
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  resendPassword(email: string) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response' as 'response',
      responseType: 'text' as 'text',
    };

    return this.http.post(`${environment.apiURL}/user/resend/password?email=${email}`, {}, options).pipe(
      tap(() => {
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getOauthToken(code: string, email: string): Observable<string> {
    let timezone: string;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.post(
      `${environment.apiURL}/user/oauth?code=${code}&email=${email}&timezone=${timezone}`,
      {},
      { responseType: 'text' }
    );
  }

  userHasAccessToken(email: string) {
    return this.http.get(`${environment.apiURL}/user/connected?email=${email}`, {}).pipe(
      timeout(5000),
      tap(() => {
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getNotionUserId(email: string) {
    return this.http.get(`${environment.apiURL}/user/me?email=${email}`, {responseType: 'text'}).pipe(
      tap(() => {
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

}
